<template>
    <div class="stop">

        <div class="row" v-if="!isStart">
            <div class="name">To</div>
            <div class="value">
                <span v-if="stop.to && stop.to.address">{{ stop.to.lat }}, {{ stop.to.lng }}</span>
                <LocationSearch v-model="stop.to" :hideSelection="true"/>
            </div>
        </div>

        <div class="row" v-if="!isEnd">
            <div class="name">Start date</div>
            <div class="value datepicker" :class="[{ 'active' : datePickerShown }]" v-click-outside="hideDatepicker">
                <a href="" @click.prevent="showHideDatePicker">{{ stop.startDate || 'Not selected' }}</a>
                <div  class="datepicker-item" v-show="datePickerShown"><DatePicker mode="datetime" v-model="stop.startDate"/></div>
            </div>
        </div>

        <div class="row" v-if="isStart">
            <div class="name">From</div>
            <div class="value">
                <span v-if="stop.from && stop.from.address">{{ stop.from.lat }}, {{ stop.from.lng }}</span>
                <LocationSearch v-model="stop.from" :hideSelection="true"/>
            </div>
        </div>
        

        <div class="row" v-if="stopKey !== -1 && !isStart">
            <div class="name">Wait time (in minutes)</div>
            <div class="value">
                <input class="input-value" v-model="stop.wait"/>
            </div>
        </div>
        

    </div>
</template>

<script>
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import LocationSearch from '../../../components/dispatch/LocationSearch.vue'
    export default {
        components: {
            DatePicker,
            LocationSearch
        },
        props: ['stop', 'stopKey', 'isStart', 'isEnd'],
        data() {
            return {
                datePickerShown: false
            }
        },
        methods: {
            showHideDatePicker() {
                this.datePickerShown = !this.datePickerShown;
            },
            hideDatepicker() {
                this.datePickerShown = false;
            }
        },
    }
</script>

<style lang="scss" scoped>
.stop {
    padding: 10px;
    border: 1px solid #eee;
    background: #fff;
    border-radius: 10px;
    display: grid;
    row-gap: 15px;
}

.row {
    display: grid;
    row-gap: 5px;
    .name {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
    }
    .value {
        font-size: 12px;
    }
}

.input-value {
    height: 34px;
    border: 1px solid $borderColor;
    width: 100%;
    border-radius: $borderRadius;
    padding: 0 10px;
}

.datepicker {
    position: relative;
    z-index: 1;
    &.active {
        z-index: 1000;
    }
    .datepicker-item {
        position: absolute;
        top: 100%;
        left: 0;
    }
}
</style>