<template>
    <div class="map-page">
        <div class="map" ref="MapHolder"></div>


        <div class="create-holder">
            <div class="content">
                <h2>{{ isEditing ? 'Edit tracking' : 'Create tracking' }}</h2>

                <div class="stops">

                    <Input name="Driver name" v-model="item.driver" placeholder="Enter driver's name to show"/>
                    <Input name="Truck #" v-model="item.truckNumber" placeholder="Enter truck number"/>
                    <Input name="Trailer #" v-model="item.trailerNumber" placeholder="Enter trailer number"/>

                    <div>
                        <h3>Start</h3>
                        <StopItem :stop="item.start" :stopKey="0" :isStart="true"/>
                    </div>

                    <div class="stop-item" :key="key" v-for="(item, key) of item.stops">
                        <StopItem :stop="item" :stopKey="key"/>
                        <div><a href="" @click.prevent="removeStop(key)">Remove stop</a></div>
                    </div>

                    <div><a href="" @click.prevent="addStop">Add stop</a></div>

                    <div>
                        <h3>End</h3>
                        <StopItem :stop="item.end" :stopKey="-1" :isEnd="true"/>
                    </div>
                </div>

                <div>
                    <div v-if="item.distance">Total miles: {{ item.distance }} miles</div>
                    <div v-if="item.eta">ETA: {{ getDateTime(item.eta) }}</div>
                </div>

                <button class="default medium" @click="validateTracking">Test</button>
                <button class="default medium" @click="createTracking" v-if="details && details.length > 0">{{ isEditing ? 'Save changes' : 'Create tracking' }}</button>
            </div>
        </div>

        <div class="create-holder right" v-if="details && details.length > 0">
            <div class="content">
                <div class="location" :class="[ { 'is-stop' : item.stop } ]" :key="key" v-for="(item, key) of details" @mouseenter="showMarkerOnMap(item.position)" @dblclick="zoomTo(item.position)">
                    <div class="lat-lng">{{ item.position.lat }}, {{ item.position.lng }}</div>
                    <div class="date">{{ getDateTime(item.date) }}</div>
                    <div class="date">{{ item.distance }} miles</div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import StopItem from './components/StopItem.vue';
import moment from 'moment';
import { toRaw } from '@vue/reactivity';

    export default {
        components: {
            StopItem
        },
        data() {
            return {
                markers: [],
                polylines: [],
                mapItem: {
                    map: null
                },
                item: {
                    start: {},
                    stops: [],
                    end: {}
                },
                details: [],
                stopsMarkers: []
            }
        },
        computed: {
            isEditing() {
                return (this.$route.params.id) ? true : false;
            }
        },
        methods: {
            addStopMarker(item, label) {
                if(!item || !item.lat || !item.lng) return;
                const marker = new this.mapItem.google.maps.Marker({
                    position: {
                        lat: item.lat,
                        lng: item.lng
                    },
                    map: this.mapItem.map,
                    label: label,
                    draggable: true
                });
                marker.addListener('dragend', (ev) => {
                    item.lat = ev.latLng.lat();
                    item.lng = ev.latLng.lng();
                });
                
                this.stopsMarkers.push(marker);
            },
            showStopsOnTheMap() {

                for(const item of this.stopsMarkers) {
                    toRaw(item).setMap(null);
                }
                this.stopsMarkers = [];
                if(this.item.start && this.item.start.from) this.addStopMarker(this.item.start.from, 'A');
                if(this.item.end && this.item.end.to) this.addStopMarker(this.item.end.to, 'B');
                
                let i = 1;
                for(const stop of this.item.stops) {
                    if(stop.to) this.addStopMarker(stop.to, 'S' + i);
                    i++;
                }

            },
            getTrackingDetails() {
                if(!this.isEditing) return;
                this.ajax('GetTrackingDetails', {
                    method: 'get',
                    url: '/tracking/details/' + this.$route.params.id,
                    data: this.item
                }, (err, body) => {
                    if(!err) {
                        this.item = body.item;
                        this.details = body.details;
                    }
                });
            },
            createTracking() {
                let item = { ...this.item };
                if(this.isEditing) {
                    item._id = this.$route.params.id
                }
                this.ajax('CreateTracking', {
                    method: 'post',
                    url: '/tracking/create',
                    data: item
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$router.push({ path: '/app/tracking' })
                });
            },
            drawRoute() {

                for(const item of this.markers) {
                    toRaw(item).setMap(null);
                }
                this.markers = [];

                for(const item of this.polylines) {
                    toRaw(item).setMap(null);
                }
                this.polylines = [];


                let coordinates = this.details.map(item => {
                    return item.position;
                });

                const myPath = new google.maps.Polyline({
                    path: coordinates,
                    geodesic: true,
                    strokeColor: "#FF0000",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });

                myPath.setMap(this.mapItem.map);
                this.polylines.push(myPath)
            },
            zoomTo(item, zoom = 10) {
                let pos = {
                    lat: item.lat,
                    lng: item.lng,
                }
                this.mapItem.map.setZoom(zoom);
                this.mapItem.map.panTo(pos); 
            },
            showMarkerOnMap(item) {
                for(const item of this.markers) {
                    toRaw(item).setMap(null);
                }
                this.markers = [];

                let pos = {
                    lat: item.lat,
                    lng: item.lng,
                }
                const marker = new this.mapItem.google.maps.Marker({
                    position: pos,
                    map: this.mapItem.map,
                    icon: {
                        url: '/images/location-button.png',
                        scaledSize: new this.mapItem.google.maps.Size(20, 20),
                        anchor: new this.mapItem.google.maps.Point(10, 10),
                    }
                });

                this.mapItem.map.panTo(pos); 
                this.markers.push(marker);
            },
            getDateTime(date) {
                return moment(date).format("MM/DD/YYYY hh:mm A")
            },
            addStop() {
                this.item.stops.push({
                    from: {
                        lat: 39.76788904952843,
                        lng:  -102.06309910174043
                    },
                    to: {
                        lat: 39.76788904952843,
                        lng:  -102.06309910174043
                    }
                })
            },
            removeStop(key) {
                this.item.stops.splice(key, 1)
            },
            validateTracking() {
                this.details = [];
                this.ajax('ValidateTracking', {
                    method: 'post',
                    url: '/tracking/validate',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Try again!')
                        return;
                    }
                    this.item = body.item;
                    this.details = body.details;
                });
            },
            createAppInstance() {
                
                const loader = new Loader({
                    apiKey: "AIzaSyDLxJWw58qz7U2DCEpEW71sFvQRQH8mURQ",
                    version: "weekly",
                    libraries: ["directions"]
                });

                const mapOptions = {
                    center: {
                        lat: 39.76788904952843,
                        lng:  -102.06309910174043
                    },
                    zoom: 4
                };

                let that = this;
                loader
                .load()
                .then((google) => {
                    that.mapItem.google = google;
                    that.mapItem.directionsService = new google.maps.DirectionsService();
                    that.mapItem.directionsRenderer = new google.maps.DirectionsRenderer();
                    that.mapItem.map = new google.maps.Map(this.$refs['MapHolder'], mapOptions);
                    that.mapItem.directionsRenderer.setMap(that.mapItem.map);
                })
                .catch(e => { });

            }
        },
        mounted() {
            this.createAppInstance();
            this.getTrackingDetails();
        },
        watch: {
            details() {
                this.drawRoute()
            },
            "item.start": {
                deep: true,
                handler() {
                    this.showStopsOnTheMap();
                }
            },
            "item.end": {
                deep: true,
                handler() {
                    this.showStopsOnTheMap();
                }
            },
            "item.stops": {
                deep: true,
                handler() {
                    this.showStopsOnTheMap();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.create-holder {
    position: absolute;
    top: 50px;
    left: 50px;
    bottom: 50px;
    width: 430px;
    background: #fff;
    border-radius: 10px;
    box-shadow: $boxShadow;
    border: 1px solid #eee;
    overflow-y: auto;
    .content {
        padding: 30px;
        display: grid;
        row-gap: 15px;
    }
    &.right {
        left: auto;
        right: 50px;
        width: 330px;
        .content {
            padding: 0;
            row-gap: 0;
        }
    }
}

.stops {
    display: grid;
    row-gap: 20px;
}

.stop-item {
    display: grid;
    row-gap: 5px;
}

.location {
    border-bottom: 1px solid $borderColor;
    padding: 15px 20px 15px 20px;
    display: grid;
    row-gap: 5px;
    &:last-child {
        border: 0;
    }
    &.is-stop {
        border-left: 4px solid $buttonColor;
    }
    .lat-lng {
        font-weight: 500;
        font-size: 12px;
    }
    .date {
        color: $buttonColor;
        font-size: 12px;
    }
}

</style>