<template>
    <div class="locationSearchGridHolder">
        <div class="locationGroupName">{{ name }}</div>
        <div class="locationSearch" :class="{ 'active' : show }" >
            <div v-click-outside="hideResults">
                <div class="locationInputHolder">
                    <input class="searchInput" v-model="search" placeholder="Search for address" @focus="handleSearch()" @keyup.enter="handleSearch"/>
                    <span class="icon"><i class="fa-solid fa-turn-up"></i></span>
                </div>
                <div class="searchResults" v-show="show && results.length > 0">
                    <div class="resultItem" :key="key" v-for="(item, key) of results" @click="selectLocation(item)">
                        <strong class="resultName">{{ item.structured_formatting.main_text }}</strong>
                        <small class="resultAddress">{{ item.structured_formatting.secondary_text }}</small>
                    </div>
                </div>
            </div>
            <div class="selection" v-if="!hideSelection">

                <div class="selectionInputHolder">
                    <div class="inputGroup">
                        <div class="name">Name</div>
                        <input type="text" v-model="selection.name">
                    </div>

                    <div class="rowOfThree" :class="{ 'rowOfOne' : disableGrid }">
                        <div class="inputGroup">
                            <div class="name">Street</div>
                            <input type="text" v-model="selection.addressLine1">
                        </div>

                        <div class="inputGroup">
                            <div class="name">City</div>
                            <input type="text" v-model="selection.addressLine2">
                        </div>

                        <div class="inputGroup">
                            <div class="name">Zip</div>
                            <input type="text" v-model="selection.zip">
                        </div>

                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

    export default {
        props: ['modelValue', 'name', 'disableGrid', 'hideSelection'],
        data() {
            return {
                search: '',
                selection: this.modelValue || {},
                results: [],
                show: false
            }
        },
        computed: {
            cityState() {
                if(!this.selection) return null;
                if(this.selection.city && this.selection.state) {
                    return this.selection.city + ', ' + this.selection.state;
                }
                return null;
            }
        },
        methods: {
            hideResults() {
                this.show = false;
            },
            selectLocation(item) {
                let placeID = item.place_id;
                this.selection = {
                    name: item.structured_formatting.main_text
                }
                this.hideResults();
                this.getPlaceFullDetails(placeID);
            },
            getPlaceFullDetails(id) {
                axios.get(`/placesFullAddress/${id}`)
                .then(data => {
                    this.selection = Object.assign({}, this.selection, data.data);
                })
                .catch(err => {
                    console.log("hmm");
                });
            },
            handleSearch() {
                this.show = true;
                if(this.search.length <= 0) return;
                axios.get(`/places/${this.search}`)
                .then(data => {
                    this.results = data.data;
                })
                .catch(err => {
                    this.results = [];
                });
            }
        },
        watch: {
            selection: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.selection);
                }
            },
            cityState() {
                this.$emit('update:cityState', this.cityState);
            },
            modelValue: {
                handler() {
                    this.selection = this.modelValue || {};
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

.locationInputHolder {
    position: relative;
    .icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        transform: rotate(90deg);
        font-size: 12px;
        opacity: 0.7;
    }
}

.locationSearchGridHolder {
    display: grid;
    row-gap: 10px;
    .locationGroupName {
        font-weight: bold;
    }
}

.searchInput {
    width: 100%;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    border: 1px solid $borderColor;
    border-radius: 5px;
    background: $bgSecondary;
}

.locationSearch {
    position: relative;
    z-index: 999;
    display: grid;
    row-gap: 10px;
    &.active {
        z-index: 1000;
    }
}

.searchResults {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    min-height: 40px;
    overflow: hidden;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 5px;
    .resultItem {
        padding: 10px;
        cursor: pointer;
        .resultName {
            font-weight: bold;
            font-size: 14px;
        }
        .resultAddress {
            font-size: 12px;
            opacity: 0.7;
            margin-left: 5px;
        }
        &:hover {
            background: $borderColor;
        }
    }
}

.rowOfThree {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 10px;
    row-gap: 10px;
    &.rowOfOne {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.inputGroup {
    width: 100%;
    display: grid;
    row-gap: 5px;
    input {
        width: 100%;
        height: 34px;
        padding: 0 10px;
        border: 1px solid $borderColor;
        border-radius: 5px;
    }
}

.selectionInputHolder {
    display: grid;
    row-gap: 10px;
}
</style>